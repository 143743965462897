<template>
  <div>
    <!-- <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h1>直播电商 满足您直播的一切需求</h1></div>
          <div>
            <p> 您可以随时开展直播活动，边播边卖、沉浸式购物，流量高效变现，培养您的私域社群和忠实客户</p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="contWidth1440 marginAuto wuhuoyuan">
      <div class="wuhuoyuan_left">
        <h1>多渠道直播预热 快速引流获客</h1>
        <div
          style="width: 550px; margin-top: 30px; font-size: 18px; color: #666"
        >
          提前曝光直播福利，嵌入公众号推文信息和菜单<br />
          独立小程序，短信直播消息推送、社群群发，朋友圈<br />
          直播间首页开启活动预热
        </div>
        <a-button class="tryBtns">
          <a
            style="color: #fff"
            href="https://console.wanjingai.com/user/register"
            >立即试用</a
          >
          <a-icon type="right" style="color: #fff; margin-left: 10px" />
        </a-button>
      </div>
      <div class="wuhuoyuan_right">
        <img src="../../assets/home/bgR2.png" style="width: 643px" />
      </div>
    </div>

    <div style="background: #f6f8fa">
      <div class="contWidth1440 marginAuto wuhuoyuan">
        <div class="wuhuoyuan_right">
          <img src="../../assets/home/bgR3.png" style="width: 643px" />
        </div>
        <div class="wuhuoyuan_left" style="width: 600px">
          <h1>丰富定制化内容展示形式，提高流量转化</h1>
          <div
            style="width: 550px; margin-top: 30px; font-size: 18px; color: #666"
          >
            定制直播专题页、预热页，微官网<br />
            自定义商城装修等定制内容形式 <br />
            满足不同渠道场景推广，流量沉淀无外流
          </div>
          <a-button class="tryBtns">
            <a
              style="color: #fff"
              href="https://console.wanjingai.com/user/register"
              >立即试用</a
            >
            <a-icon type="right" style="color: #fff; margin-left: 10px" />
          </a-button>
        </div>
      </div>
    </div>

    <div class="contWidth1440 marginAuto wuhuoyuan">
      <div class="wuhuoyuan_left">
        <h1>实现多平台用户互动</h1>
        <div style="width: 550px; margin-top: 30px; font-size: 18px">
          商城集成小程序直播的插件，用户可以同时在视频号和自有商城里面进入直播间，通过实时的娱乐性视频与客户对话和互动，公域和私域两不耽误，满足您的直播需求，给客户带来更好的购物体验<br /><br />
        </div>
        <a-button class="tryBtns">
          <a
            style="color: #fff"
            href="https://console.wanjingai.com/user/register"
            >立即试用</a
          >
          <a-icon type="right" style="color: #fff; margin-left: 10px" />
        </a-button>
      </div>
      <div class="wuhuoyuan_right">
        <img src="../../assets/home/bgR4.png" />
      </div>
    </div>

    <div style="background: #f6f8fa">
      <div class="contWidth1440 marginAuto wuhuoyuan" style="padding: 120px 0">
        <div class="wuhuoyuan_right">
          <img src="../../assets/home/bgR5.png" style="width: 565px" />
        </div>
        <div class="wuhuoyuan_left" style="width: 500px">
          <h1>多维度数据分析 带货效果可视化</h1>
          <div
            style="width: 550px; margin-top: 30px; font-size: 18px; color: #666"
          >
            用户行为分析和标签，直播观看时长和峰值并发
            商品访问和订单统计,佣金统计<br />
            订单状态转化等数据一目了然推广效果分析<br />
            决策更有的推广方案<br />
          </div>
          <a-button class="tryBtns">
            <a
              style="color: #fff"
              href="https://console.wanjingai.com/user/register"
              >立即试用</a
            >
            <a-icon type="right" style="color: #fff; margin-left: 10px" />
          </a-button>
        </div>
      </div>
    </div>
    <div class="contWidth1440 marginAuto wuhuoyuan">
      <div class="wuhuoyuan_left">
        <h1>更多营销互动玩法，促进下单</h1>
        <div style="width: 550px; margin-top: 30px; font-size: 18px">
          优惠券，报名有礼，邀请有礼，秒杀，限时折扣<br />
          分销裂变，红包雨等多样营销互动玩法<br />
          直播导购一键转发、裂变百万流量，刺激客户购买<br />
          带动高效转化
        </div>
        <a-button class="tryBtns">
          <a
            style="color: #fff"
            href="https://console.wanjingai.com/user/register"
            >立即试用</a
          >
          <a-icon type="right" style="color: #fff; margin-left: 10px" />
        </a-button>
      </div>
      <div class="wuhuoyuan_right">
        <img src="/mp/miaosha.png" />
      </div>
    </div>

    <div class="section-body-16" style="padding: 120px 0">
      <div class="container_body-5 faq w-container">
        <div class="jiantext" style="margin-bottom: 60px">
          <h1><strong>直播电商常见问题</strong></h1>
        </div>
        <div class="content-warp_faq-1">
          <div class="content-warp_faq-2">
            <div class="content-wrap_5 faq" @click="fqa1 = !fqa1">
              <p class="paragraph_body-p1">1. 直播功能设置路径？</p>
              <div :class="fqa1 ? 'kkks' : 'kkk'">
                <a-icon
                  :type="fqa1 ? 'up' : 'down'"
                  style="font-size: 12px"
                  class="image_faq"
                />
              </div>
            </div>
            <p class="paragraph_body-p2 gray faq" v-show="fqa1">
              登录店铺后台，打开【小程序直播】，进行设置
            </p>
          </div>

          <div class="content-warp_faq-2">
            <div class="content-wrap_5 faq" @click="fqa2 = !fqa2">
              <p class="paragraph_body-p1">2. 支持哪些功能？</p>
              <div :class="fqa2 ? 'kkks' : 'kkk'">
                <a-icon
                  :type="fqa2 ? 'up' : 'down'"
                  style="font-size: 14px"
                  class="image_faq"
                />
              </div>
            </div>
            <p class="paragraph_body-p2 gray faq" v-show="fqa2">
              小程序直播插件拥有强大的功能，满足直播一切需求，具体功能可以点击：
              <a
                style="color: #666"
                href="https://cloud.tencent.com/solution/tbl"
                >https://cloud.tencent.com/solution/tbl</a
              >
              查看
            </p>
          </div>

          <div class="content-warp_faq-2">
            <div class="content-wrap_5 faq" @click="fqa3 = !fqa3">
              <p class="paragraph_body-p1">3. 直播电商值得做吗？</p>
              <div :class="fqa3 ? 'kkks' : 'kkk'">
                <a-icon
                  :type="fqa3 ? 'up' : 'down'"
                  style="font-size: 14px"
                  class="image_faq"
                />
              </div>
            </div>
            <p class="paragraph_body-p2 gray faq" v-show="fqa3">
              传统电商营销方式是通过互联网广告和线下广告来引流，直播电商越来越成为一种新兴的推广方式
            </p>
          </div>
        </div>
      </div>
    </div>
    <HomeFooterBanner></HomeFooterBanner>
  </div>
</template>

<script>
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

/**
 * @author  XuHongli
 * @date  2023/3/3 14:24
 * @version 1.0
 * @description liveSales
 */
export default {
  name: "LiveSales",
  components: { HomeFooterBanner },

  data() {
    return {
      fqa1: false,
      fqa2: false,
      fqa3: false,
      openStyle:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)",
      closeStyle:
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/shopline.scss";
.bgone {
  background: url(../../assets/home/bg2.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
